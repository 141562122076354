// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref } from 'vue'

// Note that within this small-ish system, the English translation
// keys are the actual phrases used directly in the UI. This makes
// development easier and more intuitive. By sending an alert if the
// key does not exist, it reminds the developer to provide a new
// translation for each of the languages each time a new phrase is added.
//
// After adding the tutorial text which is quite long, it was decided
// to also support values for the English text keys in order to not
// have to duplicate the extremely long keys for the other languages,
// so now either the text or a key (for long text) can be used in the
// source code.

export const translations = {
  'en_US.UTF-8': {  // English
    'dātma.360': '', // the product name shown as 'alt' on logo image
    // Tool titles:
    'Chat': '',
    'Notes': '',
    'Unknown Tool': '',
    'This error should not happen. Please contact dātma for assistance.': '', // error message
    'No patient is currently selected.': '',  // rendered in tool container in place of tool.
    // UI elements:
    'Add tools': '', // for add tools button
    'Authenticating…': '', // on Login.vue page
    'Cancel': '', // to cancel modal dialogs
    'Change theme to:': '', // for theme button
    'Choose one or more tools to add:': '', // for add tools modal
    'Close tool': '', // for close button on each tool
    'Configure tool': '', // for configure tool button on each tool
    'Expand tool to full screen': '',  // for button on each tool
    'My Profile': '', // for button to switch to profile page
    'Open Control Dashboard': '', // for dashboard button
    'Return tool to original size': '',  // for button on each tool
    'Start typing message…': '', // for chat input placeholder
    'That session cannot be found.': '', // error message
    'Undo last action': '', // for undo button
    'Update': '', // for add/remove collaborators modal
    'You do not have access to that session.': '', // error message
    // Theme names:
    'dark': '', // generic name for kind of theme
    'light': '', // generic name for kind of theme
    'dātma dark': '', // default theme name
    'dātma light': '', // default theme name
    'Midnight': '',
    'MediumGray': '',
    'Bright': '',
    'NightMarsh': '',
    'Creamy': '',
    'Rusty': '',
    'ODA 2022': '',
    // Session page:
    'Patient ID': '',
    'Find a patient': '',  // used where patient info is displayed.
    'Deselect patient': '', // title for header deselect patient button
    'Search for a patient': '', // search box placeholder text
    'Search': '', // search button
    'Name': '', // patient name
    'Gender': 'Sex', // patient gender
    'Birthdate': '', // patient birthdate
    'MRN': '', // patient medical record number
    'No patients found.': '', // empty search results message
    'timeout error': 'No response in allotted time. Please retry or contact dātma if this problem persists.',
    'tool error': 'Tool returned error response. Please contact dātma for assistance.',
    // Patient card:
    'Age': '',
    'Primary Diagnosis': '',
    'Staging': '',
    // Profile page:
    'Your Displayed Name (as shown in URLs and in Chat sessions)': '',
    'Name cannot contain any of the following characters:': '',  // error message
    'Your Displayed Name is not unique and will not be displayed in URLs.': '',  // error message
    'Your Preferred Language': '',
    'Chinese (Simplified)': '',
    'Chinese (Traditional)': '',
    'English': '',
    'French': '',
    'Turkish': '',
    'Your Preferred Light Theme': '',
    'Your Preferred Dark Theme': '',
    'Time Zone': '',
    'Show Confirmation Before Deleting Session': '',
    'Sign Out': '', // sign out button text
    'Start Tutorial': '',
    'Return': '', // Meaning "Return to session" button text - keep it short.
    'Software Version:': '',
    // Time Zones:
    'Niue (UTC−11)': '',
    'Hawaii–Aleutian Islands (UTC−10)': '',
    'Alaska (UTC−9)': '',
    'Pacific (UTC−8)': '',
    'Mountain (UTC−7)': '',
    'Central (UTC−6)': '',
    'Eastern (UTC−5)': '',
    'Atlantic (UTC−4)': '',
    'Brasília (UTC−3)': '',
    'South Georgia (UTC−2)': '',
    'Cape Verde (UTC−1)': '',
    'Western Europe (UTC)': '',
    'Central Europe (UTC+1)': '',
    'Eastern Europe (UTC+2)': '',
    'Moscow (UTC+3)': '',
    'Dubai (UTC+4)': '',
    'Maldives (UTC+5)': '',
    'India (UTC+5:30)': '',
    'Kyrgyzstan (UTC+6)': '',
    'Indochina (UTC+7)': '',
    'China (UTC+8)': '',
    'Japan (UTC+9)': '',
    'Guam (UTC+10)': '',
    'Vanuatu (UTC+11)': '',
    'New Zealand (UTC+12)': '',
    // Dashboard page:
    'Control Dashboard': '',
    'Create new session': '',
    'Choose a starting template:': '',
    'Sessions owned by me': '',
    'Sessions shared with me': '',
    'Owner': '',
    'Session ID': '',
    'Session Name (optional)': '',
    'Your Session Name must be unique.': '',  // error message
    'Session Name': '',
    'Session Link': '',
    'Number of Tools': '',
    'Locally Anonymized': '',
    'Copy Session Link': '',
    'Sharing': '',
    'Collaborators': '',
    'Viewers (read-only)': '',
    'Last accessed': '',
    'Duplicate': '',
    'Sharing Status': '',
    'Delete': '',  // session
    'Go to session': '', // button icon title
    'Copy session link for sharing': '', // button icon title
    'Successfully copied!': '', // copy-to-clipboard success message
    'Add or remove collaborators': '', // button icon title
    'Add or remove viewers': '', // button icon title
    'All users with the link': '', // collaborators modal dialog
    'Make a copy of this session': '', // button icon title
    'Collaborator': '', // non-button icon title, sharing status
    'Viewer': '', // non-button icon title, sharing status
    'Delete this session': '', // button icon title
    'never': '',  // displayed in 'Last accessed' column if never displayed
    'Delete Session': '', // Title of session deletion confirmation modal
    'Deleting this session is permanent and cannot be undone.': '', // Text of session deletion confirmation modal
    // Dashboard tips
    'dashboard-tip-session-name': 'You can assign a name to a session that will be used in the URL for that session. The name should be unique among your sessions.',
    'dashboard-tip-session-link': 'Clicking the “Go to session” icon will open that session.',
    'dashboard-tip-locally-anonymized': 'When this is selected, the patient name will not be displayed locally.',
    'dashboard-copy-tip-session-link': 'Clicking the “Copy session link for sharing” icon will copy the session URL to your clipboard for sharing.',
    'dashboard-tip-collaborators': 'This opens a dialog where you can add or remove session collaborators, which are users who have full editing ability in a session that is shared with them.',
    'dashboard-tip-viewers': 'This opens a dialog where you can add or remove session viewers, which are users who can view but not edit a session that is shared with them.',
    'dashboard-tip-duplicate-session': 'Duplicating a session will create an exact copy of that session, including its tools, their sizes and positions, and the selected patient. The new copy will be independent of the original, so any edits made in either session will not be reflected in the other one.',
    'dashboard-tip-delete-session': 'Deleting a session is permanent and cannot be undone.',
    'shared-dashboard-tip-session-name': 'If the session owner who is sharing a session with you has given it a name, that is what will initially be displayed here. You can, however, change the session name for yourself; only you will know the session by a name you assign to it.',
    'shared-dashboard-tip-session-link': 'Clicking the “Go to session” icon will open that session.',
    'shared-dashboard-tip-locally-anonymized': 'When this is selected, the patient name will not be displayed locally.',
    'shared-dashboard-tip-duplicate-session': 'Duplicating a shared session will create an exact copy of that session, including its tools, their sizes and positions, and the selected patient, which will be owned by you. Because you will own the duplicate, you will have full editing ability within that new session.',
    'shared-dashboard-tip-sharing-status': 'For each session that is shared with you, you are either a viewer, meaning the session is read-only for you, or a collaborator, meaning the session is editable by you.',
    // Notes Tool Menu Bar Item Titles
    'Bold (Ctrl+B)': '',
    'Bold (⌘+B)': '',
    'Italic (Ctrl+I)': '',
    'Italic (⌘+I)': '',
    'Strike (Ctrl+Shift+X)': '',
    'Strike (⌘+Shift+X)': '',
    'Heading 1 (Ctrl+Alt+1)': '',
    'Heading 1 (⌘+Alt+1)': '',
    'Heading 2 (Ctrl+Alt+2)': '',
    'Heading 2 (⌘+Alt+2)': '',
    'Paragraph (Ctrl+Alt+0)': '',
    'Paragraph (⌘+Alt+0)': '',
    'Left Align (Ctrl+Shift+L)': '',
    'Left Align (⌘+Shift+L)': '',
    'Center Align (Ctrl+Shift+E)': '',
    'Center Align (⌘+Shift+E)': '',
    'Right Align (Ctrl+Shift+R)': '',
    'Right Align (⌘+Shift+R)': '',
    'Justify (Ctrl+Shift+J)': '',
    'Justify (⌘+Shift+J)': '',
    'Bullet List (Ctrl+Shift+8)': '',
    'Bullet List (⌘+Shift+8)': '',
    'Ordered List (Ctrl+Shift+7)': '',
    'Ordered List (⌘+Shift+7)': '',
    'Horizontal Rule': '',
    'Hard Break (Shift+Enter)': '',
    'Clear Format': '',
    'Undo (Ctrl+Z)': '',
    'Undo (⌘+Z)': '',
    'Redo (Ctrl+Y)': '',
    'Redo (⌘+Y)': '',
    // Tutorial steps:
    'tutorial-step-1-title': 'Welcome to MyPatient 360°! (1/12)',
    'tutorial-step-1': '<ul><li>Use the Profile page to customize your MyPatient 360° experience</li><li>Make a unique username</li><li>Press ESC to exit tutorial early</li></ul>',
    'tutorial-step-2-title': 'Preferences (2/12)',
    'tutorial-step-2': '<ul><li>Set your preferred language, light theme, dark theme, and time zone</li><li>Try some of the available themes now</li><li>Verify your time zone is correct</li></ul>',
    'tutorial-step-3-title': 'Theme Toggle (3/12)',
    'tutorial-step-3': '<ul><li>The theme toggle button is available in the header on every page.</li><li>Try using the theme toggle to switch between your preferred themes</li></ul>',
    'tutorial-step-4-title': 'The Dashboard (4/12)',
    'tutorial-step-4': '<ul><li>The Dashboard is the central location to manage your Sessions. Here, you can:<br>&nbsp;&nbsp;&nbsp;create new Sessions<br>&nbsp;&nbsp;&nbsp;name Sessions<br>&nbsp;&nbsp;&nbsp;anonymize patient data<br>&nbsp;&nbsp;&nbsp;open Sessions and copy their URLs<br>&nbsp;&nbsp;&nbsp;share Sessions<br>&nbsp;&nbsp;&nbsp;duplicate Sessions<br>&nbsp;&nbsp;&nbsp;delete Sessions</li><li>Deleting a Session is permanent and cannot be undone, so be careful when you use that button</li></ul>',
    'tutorial-step-5-title': 'Create a New Session (5/12)',
    'tutorial-step-5': '<ul><li>Open the “Create new session” dialog</li></ul>',
    'tutorial-step-6-title': 'Choose a Starting Template (6/12)',
    'tutorial-step-6': '<ul><li>Session templates contain a pre-designed set of tools</li><li>A blank Session starts empty</li><li>Select the “Blank” option</li></ul>',
    'tutorial-step-7-title': 'This is a New Session (7/12)',
    'tutorial-step-7': '<ul><li>Sessions are where you will spend most of your time</li><li>Click “Add Tools”</li></ul>',
    'tutorial-step-8-title': 'Add a Tool (8/12)',
    'tutorial-step-8': '<ul><li>Here, you can add one or more new tools to a Session</li><li>Select the Clinical Timeline and Notes tools, then click “Add tools”</li></ul>',
    'tutorial-step-9-title': 'Select a Patient (9/12)',
    'tutorial-step-9': '<ul><li>Some tools (like Notes) you can start using right away</li><li>Others (like Clinical Timeline) need patient data</li><li>Select a patient by clicking “Find a patient” in the header</li></ul>',
    'tutorial-step-10-title': 'Select a Patient (10/12)',
    'tutorial-step-10': '<ul><li>Search for patients by name or MRN</li><li>Enter “0” to find every patient in the database (try this now)</li><li>Click “Search” or press the “Enter” key on your keyboard</li></ul>',
    'tutorial-step-11-title': 'Select a Patient (11/12)',
    'tutorial-step-11': '<ul><li>Click on any patient row to select a particular patient</li></ul>',
    'tutorial-step-12-title': 'Now Try Exploring! (12/12)',
    'tutorial-step-12': '<ul><li>The selected patient’s data is now in this Session’s tools</li><li>Change the selected patient at any time by clicking on the patient information in the header</li><li>Now have fun familiarizing yourself with available tools and their features</li><li>Click “OK” to end the tutorial</li></ul>',
    // ViewerTool
    'Download CSV': '',
    'Download TSV': '',
    'Download Markdown': '',
    'Download SVG': '',
    'Download PDF': '',
    'Download TXT': '',
  },
  'fr_CA.UTF-8': {  // French Canadian
    'dātma.360': 'dātma.360', // the product name shown as 'alt' on logo image
    // Tool titles:
    'Chat': 'Chat',
    'Notes': 'Notes',
    'Unknown Tool': 'Outil Inconnu',
    'This error should not happen. Please contact dātma for assistance.': "Cette erreur ne devrait pas se produire. Veuillez contacter dātma pour obtenir de l'aide.", // error message
    'No patient is currently selected.': "Aucun patient n'est actuellement sélectionné.",  // rendered in tool container in place of tool.
    // UI elements:
    'Add tools': "Ajouter d’outils", // for add tools button
    'Authenticating…': 'Authentifiant…', // on Login.vue page
    'Cancel': 'Annuler', // to cancel modal dialogs
    'Change theme to:': 'Changer de thème pour', // for theme button
    'Choose one or more tools to add:': 'Choisissez un ou plusieurs outils à ajouter :', // for add tools modal
    'Close tool': "Fermer l'outil", // for close button on each tool
    'Configure tool': "Configurer l'outil", // for configure tool button on each tool
    'Expand tool to full screen': "Étendre l'outil au plein écran",  // for button on each tool
    'My Profile': 'Mon Profil', // for button to switch to profile page
    'Open Control Dashboard': 'Tableau de Bord du Contrôle Ouvert', // for dashboard button
    'Return tool to original size': "Retourner l'outil à sa taille d'origine",  // for button on each tool
    'Start typing message…': 'Commencez à taper le message…', // for chat input placeholder
    'That session cannot be found.': 'Cette session est introuvable.',  // error message
    'Undo last action': 'Annuler la dernière action', // for undo button
    'Update': 'Mise à jour', // for add/remove collaborators modal
    'You do not have access to that session.': "Vous n'avez pas accès à cette session.",  // error message
    // Theme names:
    'dark': 'foncé', // generic name for kind of theme
    'light': 'clair', // generic name for kind of theme
    'dātma dark': 'dātma foncé',
    'dātma light': 'dātma clair',
    'Midnight': 'Minuit',
    'MediumGray': 'Gris Moyen',
    'Bright': 'Brillant',
    'NightMarsh': 'Marais de Nuit',
    'Creamy': 'Crème',
    'Rusty': 'Rouillé',
    'ODA 2022': 'ODA 2022',
    // Session page:
    'Patient ID': 'Identification du Patient',
    'Find a patient': 'Trouver un patient',  // used where patient info is displayed.
    'Deselect patient': 'TBD', // title for header deselect patient button
    'Search for a patient': 'Rechercher un patient', // search box placeholder text
    'Search': 'Recherche', // search button
    'Name': 'Nom', // patient name
    'Gender': 'Genre', // patient gender
    'Birthdate': 'Date de naissance', // patient birthdate
    'MRN': 'NDM', // patient medical record number
    'No patients found.': 'Aucun patient trouvé', // empty search results message
    'timeout error': 'No response in allotted time. Please retry or contact dātma if this problem persists. TBD',
    'tool error': 'Tool returned error response. Please contact dātma for assistance. TBD',
    // Patient card:
    'Age': 'Âge',
    'Primary Diagnosis': 'Diagnostic Principal',
    'Staging': 'Stadification',
    // Profile page:
    'Your Displayed Name (as shown in URLs and in Chat sessions)': "Votre nom affiché (tel qu'indiqué dans les URL et les sessions de chat)",
    'Name cannot contain any of the following characters:': 'Le nom ne peut contenir aucun des caractères suivants :', // error message
    'Your Displayed Name is not unique and will not be displayed in URLs.': "Votre nom affiché n'est pas unique et ne sera pas montré dans les URLs.", // error message
    'Your Preferred Language': 'Votre langue préférée',
    'Chinese (Simplified)': 'Chinois (simplifié)',
    'Chinese (Traditional)': 'Chinois (traditionnel)',
    'English': 'Anglais',
    'French': 'Français',
    'Turkish': 'Turc',
    'Your Preferred Light Theme': 'Votre thème de clair préféré',
    'Your Preferred Dark Theme': 'Votre thème de foncé préféré',
    'Time Zone': 'Fuseau horaire',
    'Show Confirmation Before Deleting Session': "Afficher la confirmation avant l'effacer de la session",
    'Sign Out': 'Déconnecter', // sign out button text
    'Start Tutorial': 'Commencer le tutoriel',
    'Return': 'Retourner', // Meaning "Return to session" button text - keep it short.
    'Software Version:': 'Version du logiciel :',
    // Time Zones:
    'Niue (UTC−11)': 'Nioué (UTC−11)',
    'Hawaii–Aleutian Islands (UTC−10)': 'Hawaï–îles Aléoutiennes (UTC−10)',
    'Alaska (UTC−9)': 'Alaska (UTC−9)',
    'Pacific (UTC−8)': 'Pacifique (UTC−8)',
    'Mountain (UTC−7)': 'Montagne (UTC−7)',
    'Central (UTC−6)': 'Central (UTC−6)',
    'Eastern (UTC−5)': 'Est (UTC−5)',
    'Atlantic (UTC−4)': 'Atlantique (UTC−4)',
    'Brasília (UTC−3)': 'Brasília (UTC−3)',
    'South Georgia (UTC−2)': 'Géorgie du Sud (UTC−2)',
    'Cape Verde (UTC−1)': 'Cap-Vert (UTC−1)',
    'Western Europe (UTC)': "Europe de l'Ouest (UTC)",
    'Central Europe (UTC+1)': 'Europe Centrale (UTC+1)',
    'Eastern Europe (UTC+2)': "Europe de l'Est (UTC+2)",
    'Moscow (UTC+3)': 'Moscou (UTC+3)',
    'Dubai (UTC+4)': 'Dubaï (UTC+4)',
    'Maldives (UTC+5)': 'Maldives (UTC+5)',
    'India (UTC+5:30)': 'Inde (UTC+5:30)',
    'Kyrgyzstan (UTC+6)': 'Kirghizstan (UTC+6)',
    'Indochina (UTC+7)': 'Indochine (UTC+7)',
    'China (UTC+8)': 'Chine (UTC+8)',
    'Japan (UTC+9)': 'Japon (UTC+9)',
    'Guam (UTC+10)': 'Guam (UTC+10)',
    'Vanuatu (UTC+11)': 'Vanuatu (UTC+11)',
    'New Zealand (UTC+12)': 'Nouvelle-Zélande (UTC+12)',
    // Dashboard page:
    'Control Dashboard': 'Tableau de Bord du Contrôle',
    'Create new session': 'Créer une nouvelle session',
    'Choose a starting template:': 'Choisis un modèle de départ :',
    'Sessions owned by me': "Sessions m'appartenant",
    'Sessions shared with me': 'Sessions partagées avec moi',
    'Owner': 'Propriétaire',
    'Session ID': 'Identification de la Session',
    'Session Name (optional)': 'Nom de la session (facultatif)',
    'Your Session Name must be unique.': 'Le nom de votre session doit être unique.',  // error message
    'Session Name': 'Nom de la session',
    'Session Link': 'Lien de la session',
    'Number of Tools': "Nombre d'outils",
    'Locally Anonymized': 'Anonymisée localement',
    'Copy Session Link': 'Copier le lien de la session',
    'Sharing': 'Partage',
    'Collaborators': 'Collaborateurs',
    'Viewers (read-only)': 'Spectateurs (lecture seule)',
    'Last accessed': 'Dernier accès',
    'Duplicate': 'Dupliquer',
    'Sharing Status': 'TBD',
    'Delete': 'Effacer',  // session
    'Go to session': 'Aller à la session', // button icon title
    'Copy session link for sharing': 'Copier le lien de la session pour le partager', // button icon title
    'Successfully copied!': 'Copié avec succès !', // copy-to-clipboard success message
    'Add or remove collaborators': 'Ajouter ou supprimer des collaborateurs', // button icon title
    'Add or remove viewers': 'Ajouter ou supprimer des spectateurs', // button icon title
    'All users with the link': 'Tous les utilisateurs ayant le lien', // collaborators modal dialog
    'Make a copy of this session': 'Faites une copie de cette session', // button icon title
    'Collaborator': 'TBD', // non-button icon title, sharing status
    'Viewer': 'TBD', // non-button icon title, sharing status
    'Delete this session': 'Effacer cette session', // button icon title
    'never': 'jamais',  // displayed in 'Last accessed' column if never displayed
    'Delete Session': 'Effacer la Session', // Title of session deletion confirmation modal
    'Deleting this session is permanent and cannot be undone.': "L'effacer de cette session est permanente et ne peut être annulée.", // Text of session deletion confirmation modal
    // Dashboard tips
    'dashboard-tip-session-name': 'TBD',
    'dashboard-tip-session-link': 'TBD',
    'dashboard-tip-locally-anonymized': 'TBD',
    'dashboard-copy-tip-session-link': 'TBD',
    'dashboard-tip-collaborators': 'TBD',
    'dashboard-tip-viewers': 'TBD',
    'dashboard-tip-duplicate-session': 'TBD',
    'dashboard-tip-delete-session': 'TBD',
    'shared-dashboard-tip-session-name': 'TBD',
    'shared-dashboard-tip-locally-anonymized': 'TBD',
    'shared-dashboard-tip-session-link': 'TBD',
    'shared-dashboard-tip-duplicate-session': 'TBD',
    'shared-dashboard-tip-sharing-status': 'TBD',
    // Notes Tool Menu Bar Item Titles
    'Bold (Ctrl+B)': 'Gras (Ctrl+B)',
    'Bold (⌘+B)': 'Gras (⌘+B)',
    'Italic (Ctrl+I)': 'Italique (Ctrl+I)',
    'Italic (⌘+I)': 'Italique (⌘+I)',
    'Strike (Ctrl+Shift+X)': 'Barré (Ctrl+Maj+X)',
    'Strike (⌘+Shift+X)': 'Barré (⌘+Maj+X)',
    'Heading 1 (Ctrl+Alt+1)': 'Rubrique 1 (Ctrl+Alt+1)',
    'Heading 1 (⌘+Alt+1)': 'Rubrique 1 (⌘+Alt+1)',
    'Heading 2 (Ctrl+Alt+2)': 'Rubrique 2 (Ctrl+Alt+2)',
    'Heading 2 (⌘+Alt+2)': 'Rubrique 2 (⌘+Alt+2)',
    'Paragraph (Ctrl+Alt+0)': 'Paragraphe (Ctrl+Alt+0)',
    'Paragraph (⌘+Alt+0)': 'Paragraphe (⌘+Alt+0)',
    'Left Align (Ctrl+Shift+L)': 'Aligner à gauche (Ctrl+Maj+L)',
    'Left Align (⌘+Shift+L)': 'Aligner à gauche (⌘+Maj+L)',
    'Center Align (Ctrl+Shift+E)': 'Centrer (Ctrl+Maj+E)',
    'Center Align (⌘+Shift+E)': 'Centrer (⌘+Maj+E)',
    'Right Align (Ctrl+Shift+R)': 'Aligner à droite (Ctrl+Maj+R)',
    'Right Align (⌘+Shift+R)': 'Aligner à droite (⌘+Maj+R)',
    'Justify (Ctrl+Shift+J)': 'Justifier (Ctrl+Maj+J)',
    'Justify (⌘+Shift+J)': 'Justifier (⌘+Maj+J)',
    'Bullet List (Ctrl+Shift+8)': 'Liste à Puces (Ctrl+Maj+8)',
    'Bullet List (⌘+Shift+8)': 'Liste à Puces (⌘+Maj+8)',
    'Ordered List (Ctrl+Shift+7)': 'Liste Ordonnée (Ctrl+Maj+7)',
    'Ordered List (⌘+Shift+7)': 'Liste Ordonnée (⌘+Maj+7)',
    'Horizontal Rule': 'Règle Horizontale',
    'Hard Break (Shift+Enter)': 'Rupture Dure (Maj+Entrée)',
    'Clear Format': 'Effacer la Mise en Forme',
    'Undo (Ctrl+Z)': 'Effacer (Ctrl+Z)',
    'Undo (⌘+Z)': 'Effacer (⌘+Z)',
    'Redo (Ctrl+Y)': 'Rétablir (Ctrl+Y)',
    'Redo (⌘+Y)': 'Rétablir (⌘+Y)',
    // Tutorial steps:
    'tutorial-step-1-title': 'Bienvenue sur MyPatient 360°! (1/12)',
    'tutorial-step-1': '<ul><li>Utilisez la page Profil pour personnaliser votre expérience MyPatient 360°</li><li>Créez un nom d’utilisateur unique</li><li>Appuyez sur ESC pour quitter le tutoriel prématurément</li></ul>',
    'tutorial-step-2-title': 'Préférences (2/12)',
    'tutorial-step-2': '<ul><li>Définissez votre langue préférée, le thème de clair, le thème de foncé, et le fuseau horaire</li><li>Essayez dès maintenant certains des thèmes disponibles</li><li>Vérifiez que votre fuseau horaire est correct</li></ul>',
    'tutorial-step-3-title': 'Toggle du thème (3/12)',
    'tutorial-step-3': '<ul><li>Le bouton de basculement du thème est disponible dans l’en-tête de chaque page.</li><li>Essayez d’utiliser la bascule de thème pour passer d’un thème à l’autre</li></ul>',
    'tutorial-step-4-title': 'Le tableau de bord (4/12)',
    'tutorial-step-4': '<ul><li>Le tableau de bord est l’endroit central pour gérer vos sessions. Ici, vous pouvez :<br>&nbsp;&nbsp;&nbsp;créer de nouvelles Sessions<br>&nbsp;&nbsp;&nbsp;nommer des sessions<br>&nbsp;&nbsp;&nbsp;anonymiser les données des patients<br>&nbsp;&nbsp;&nbsp;ouvrir des sessions et copier leurs URLs<br>&nbsp;&nbsp;&nbsp;partager des sessions<br>&nbsp;&nbsp;&nbsp;dupliquer des sessions<br>&nbsp;&nbsp;&nbsp;supprimer des sessions</li><li>L’effacer d’une session est permanente et ne peut être annulée, aussi faites attention lorsque vous utilisez ce bouton.</li></ul>',
    'tutorial-step-5-title': 'Créer une nouvelle session (5/12)',
    'tutorial-step-5': '<ul><li>Ouvrez la boîte de dialogue « Créer une nouvelle session. »</li></ul>',
    'tutorial-step-6-title': 'Choisis un modèle de départ (6/12)',
    'tutorial-step-6': '<ul><li>Les modèles de session contiennent un ensemble d’outils préconçus</li><li>Une session vide commence vide</li><li>Sélectionnez l’option « Vide. »</li></ul>',
    'tutorial-step-7-title': 'Il s’agit d’une nouvelle session (7/12)',
    'tutorial-step-7': '<ul><li>Les sessions sont celles où vous passerez le plus de temps.</li><li>Cliquez sur « Ajouter d’outils. »</li></ul>',
    'tutorial-step-8-title': 'Ajouter un outil (8/12)',
    'tutorial-step-8': '<ul><li>Ici, vous pouvez ajouter un ou plusieurs nouveaux outils à une session</li><li>Sélectionnez les outils Chronologie Clinique et Notes, puis cliquez sur « Ajouter d’outils. »</li></ul>',
    'tutorial-step-9-title': 'Sélectionnez un patient (9/12)',
    'tutorial-step-9': '<ul><li>Certains outils (comme Notes) que vous pouvez commencer à utiliser dès maintenant</li><li>D’autres (comme Chronologie Clinique) ont besoin de données sur les patients</li><li>Sélectionnez un patient en cliquant sur « Trouver un patient » dans l’en-tête</li></ul>',
    'tutorial-step-10-title': 'Sélectionnez un patient (10/12)',
    'tutorial-step-10': '<ul><li>Recherche de patients par nom ou par NDM</li><li>Entrez « 0 » pour trouver tous les patients de la base de données (essayez maintenant)</li><li>Cliquez sur « Rechercher » ou appuyez sur la touche « Entrée » de votre clavier</li></ul>',
    'tutorial-step-11-title': 'Sélectionnez un patient (11/12)',
    'tutorial-step-11': '<ul><li>Cliquez sur n’import quelle ligne de patients pour sélectionner un patient en particulier.</li></ul>',
    'tutorial-step-12-title': 'Maintenant, essayez d’explorer ! (12/12)',
    'tutorial-step-12': '<ul><li>Les données du patient sélectionné se trouvent maintenant dans les outils de cette session</li><li>Changez le patient sélectionné à tout moment en cliquant sur les informations relatives au patient dans l’en-tête</li><li>Amusez-vous maintenant à vous familiariser avec les outils disponibles et leurs caractéristiques</li><li>Cliquez sur « OK » pour terminer le tutoriel</li></ul>',
    // ViewerTool
    'Download CSV': 'Télécharger CSV',
    'Download TSV': 'Télécharger TSV',
    'Download Markdown': 'Télécharger Markdown',
    'Download SVG': 'Télécharger SVG',
    'Download PDF': 'Télécharger PDF',
    'Download TXT': 'Télécharger TXT',
  },
  'tr_TR.UTF-8': {  // Turkish
    'dātma.360': 'dātma.360', // the product name shown as 'alt' on logo image
    // Tool titles:
    'Chat': 'Sohbet',
    'Notes': 'Notlar',
    'Unknown Tool': 'Bilinmeyen Araç',
    'This error should not happen. Please contact dātma for assistance.': 'Bu hata oluşmamalıdır. Yardım için lütfen dātma ile iletişime geçin.', // error message
    'No patient is currently selected.': 'Şu anda hiçbir hasta seçilmemiştir.',  // rendered in tool container in place of tool.
    // UI elements:
    'Add tools': 'Araçlar ekle', // for add tools button
    'Authenticating…': 'Doğrulamış…', // on Login.vue page
    'Cancel': 'İptal etmek', // to cancel modal dialogs
    'Change theme to:': 'Temayı olarak değiştir', // for theme button
    'Choose one or more tools to add:': 'Eklenecek bir veya daha fazla araç seçin:', // for add tools modal
    'Close tool': 'Aracı kapat', // for close button on each tool
    'Configure tool': 'Aracı yapılandır', // for configure tool button on each tool
    'Expand tool to full screen': 'Aracı tam ekrana genişlet',  // for button on each tool
    'My Profile': 'Profilim', // for button to switch to profile page
    'Open Control Dashboard': 'Kontrol Panelini aç', // for dashboard button
    'Return tool to original size': 'Aracı orijinal boyutuna geri döndürün',  // for button on each tool
    'Start typing message…': 'Mesaj yazmaya başlayın…', // for chat input placeholder
    'That session cannot be found.': 'O oturum bulunamadı.',  // error message
    'Undo last action': 'Son eylemi geri al', // for undo button
    'Update': 'Güncelle', // for add/remove collaborators modal
    'You do not have access to that session.': 'O oturuma erişiminiz yok.',  // error message
    // Theme names:
    'dark': 'koyu', // generic name for kind of theme
    'light': 'ışık', // generic name for kind of theme
    'dātma dark': 'dātma koyu',
    'dātma light': 'dātma ışık',
    'Midnight': 'Gece Yarısı',
    'MediumGray': 'Orta Gri',
    'Bright': 'Parlak',
    'NightMarsh': 'Gece Bataklığı',
    'Creamy': 'Kremsi',
    'Rusty': 'Paslı',
    'ODA 2022': 'ODA 2022',
    // Session page:
    'Patient ID': 'Hasta Kimliği',
    'Find a patient': 'Bir hasta bul',  // used where patient info is displayed.
    'Deselect patient': 'TBD', // title for header deselect patient button
    'Search for a patient': 'Bir hasta ara', // search box placeholder text
    'Search': 'Aramak', // search button
    'Name': 'Isim', // patient name
    'Gender': 'Cinsiyet', // patient gender
    'Birthdate': 'Doğum tarihi', // patient birthdate
    'MRN': 'TKN', // patient medical record number
    'No patients found.': 'Hastalar bulunamadı.', // empty search results message
    'timeout error': 'No response in allotted time. Please retry or contact dātma if this problem persists. TBD',
    'tool error': 'Tool returned error response. Please contact dātma for assistance. TBD',
    // Patient card:
    'Age': 'Yaş',
    'Primary Diagnosis': 'Birincil Teşhis',
    'Staging': 'Evrelemesi',
    // Profile page:
    'Your Displayed Name (as shown in URLs and in Chat sessions)': "Görüntülenen Adınız (URL'lerde ve Sohbet oturumlarında gösterildiği gibi)",
    'Name cannot contain any of the following characters:': 'Ad aşağıdaki karakterlerden hiçbirini içeremez:', // error message
    'Your Displayed Name is not unique and will not be displayed in URLs.': "Görüntülenen Adınız benzersiz değil ve URL'lerde görüntülenmeyecek.", // error message
    'Your Preferred Language': 'Tercih ettiğiniz dil',
    'Chinese (Simplified)': 'Çince (Basitleştirilmiş)',
    'Chinese (Traditional)': 'Çince (Geleneksel)',
    'English': 'İngilizce',
    'French': 'Fransızca',
    'Turkish': 'Türkçe',
    'Your Preferred Light Theme': 'Tercih ettiğiniz ışık tema',
    'Your Preferred Dark Theme': 'Tercih ettiğiniz koyu tema',
    'Time Zone': 'Saat Dilimi',
    'Show Confirmation Before Deleting Session': 'Oturumu Silmeden Önce Onayı Göster',
    'Sign Out': 'Sistemden Çıkmak', // sign out button text
    'Start Tutorial': 'Öğreticiye Başla',
    'Return': 'Dönmek', // Meaning "Return to session" button text - keep it short.
    'Software Version:': 'Yazılım Sürümü:',
    // Time Zones:
    'Niue (UTC−11)': 'Niue (UTC−11)',
    'Hawaii–Aleutian Islands (UTC−10)': 'Hawaii–Aleut Adaları (UTC−10)',
    'Alaska (UTC−9)': 'Alaska (UTC−9)',
    'Pacific (UTC−8)': 'Pasifik (UTC−8)',
    'Mountain (UTC−7)': 'Dağ (UTC−7)',
    'Central (UTC−6)': 'Merkezi (UTC−6)',
    'Eastern (UTC−5)': 'Doğulu (UTC−5)',
    'Atlantic (UTC−4)': 'Atlantik (UTC−4)',
    'Brasília (UTC−3)': 'Brasilia (UTC−3)',
    'South Georgia (UTC−2)': 'Güney Georgia (UTC−2)',
    'Cape Verde (UTC−1)': 'Cape Verde (UTC−1)',
    'Western Europe (UTC)': 'Batı Avrupa (UTC)',
    'Central Europe (UTC+1)': 'Orta Avrupa (UTC+1)',
    'Eastern Europe (UTC+2)': 'Doğu Avrupa (UTC+2)',
    'Moscow (UTC+3)': 'Moskova (UTC+3)',
    'Dubai (UTC+4)': 'Dubai (UTC+4)',
    'Maldives (UTC+5)': 'Maldivler (UTC+5)',
    'India (UTC+5:30)': 'Hindistan (UTC+5:30)',
    'Kyrgyzstan (UTC+6)': 'Kırgızistan (UTC+6)',
    'Indochina (UTC+7)': 'Çinhindi (UTC+7)',
    'China (UTC+8)': 'Çin (UTC+8)',
    'Japan (UTC+9)': 'Japonya (UTC+9)',
    'Guam (UTC+10)': 'Guam (UTC+10)',
    'Vanuatu (UTC+11)': 'Vanuatu (UTC+11)',
    'New Zealand (UTC+12)': 'Yeni Zelanda (UTC+12)',
    // Dashboard page:
    'Control Dashboard': 'Kontrol Panelini',
    'Create new session': 'Yeni oturum oluştur',
    'Choose a starting template:': 'Bir başlangıç şablonu seçin:',
    'Sessions owned by me': 'Bana ait oturumlar',
    'Sessions shared with me': 'Benimle paylaşılan oturumlar',
    'Owner': 'Sahibi',
    'Session ID': 'Oturum Kimliği',
    'Session Name (optional)': 'Oturum Adı (isteğe bağlı)',
    'Your Session Name must be unique.': 'Oturum adınız benzersiz olmalıdır.',  // error message
    'Session Name': 'Oturum Adı',
    'Session Link': 'Oturum Bağ',
    'Number of Tools': 'Araç Sayısı',
    'Locally Anonymized': 'Yerel Olarak Anonimleştirilmiş',
    'Copy Session Link': 'Oturum bağlantısını kopyala',
    'Sharing': 'Paylaşım',
    'Collaborators': 'İşbirlikçiler',
    'Viewers (read-only)': 'İzleyiciler (salt okunur)',
    'Last accessed': 'Son erişilen',
    'Duplicate': 'Çoğaltmak',
    'Sharing Status': 'TBD',
    'Delete': 'Silmek',  // session
    'Go to session': 'Oturuma git', // button icon title
    'Copy session link for sharing': 'Paylaşım için oturum bağlantısını kopyala', // button icon title
    'Successfully copied!': 'Başarıyla kopyalandı!', // copy-to-clipboard success message
    'Add or remove collaborators': 'İşbirlikçiler ekleme veya kaldırma', // button icon title
    'Add or remove viewers': 'İzleyiciler ekleme veya kaldırma', // button icon title
    'All users with the link': 'Bağlantı ile tüm kullanıcılar', // collaborators modal dialog
    'Make a copy of this session': 'Bu oturumun kopyasını oluşturmak', // button icon title
    'Collaborator': 'TBD', // non-button icon title, sharing status
    'Viewer': 'TBD', // non-button icon title, sharing status
    'Delete this session': 'Bu oturumu silmek', // button icon title
    'never': 'asla',  // displayed in 'Last accessed' column if never displayed
    'Delete Session': 'Oturumu Sil', // Title of session deletion confirmation modal
    'Deleting this session is permanent and cannot be undone.': 'Bu oturumun silinmesi kalıcıdır ve geri alınamaz.', // Text of session deletion confirmation modal
    // Dashboard tips
    'dashboard-tip-session-name': 'TBD',
    'dashboard-tip-session-link': 'TBD',
    'dashboard-tip-locally-anonymized': 'TBD',
    'dashboard-copy-tip-session-link': 'TBD',
    'dashboard-tip-collaborators': 'TBD',
    'dashboard-tip-viewers': 'TBD',
    'dashboard-tip-duplicate-session': 'TBD',
    'dashboard-tip-delete-session': 'TBD',
    'shared-dashboard-tip-session-name': 'TBD',
    'shared-dashboard-tip-locally-anonymized': 'TBD',
    'shared-dashboard-tip-session-link': 'TBD',
    'shared-dashboard-tip-duplicate-session': 'TBD',
    'shared-dashboard-tip-sharing-status': 'TBD',
    // Notes Tool Menu Bar Item Titles
    'Bold (Ctrl+B)': 'Kalın (Ctrl+B)',
    'Bold (⌘+B)': 'Kalın (⌘+B)',
    'Italic (Ctrl+I)': 'İtalik (Ctrl+I)',
    'Italic (⌘+I)': 'İtalik (⌘+I)',
    'Strike (Ctrl+Shift+X)': 'Üstü Çizili (Ctrl+ÜstKrktr+5)',
    'Strike (⌘+Shift+X)': 'Üstü Çizili (⌘+ÜstKrktr+5)',
    'Heading 1 (Ctrl+Alt+1)': 'Başlığı 1 (Ctrl+Alt+1)',
    'Heading 1 (⌘+Alt+1)': 'Başlığı 1 (⌘+Alt+1)',
    'Heading 2 (Ctrl+Alt+2)': 'Başlığı 2 (Ctrl+Alt+2)',
    'Heading 2 (⌘+Alt+2)': 'Başlığı 2 (⌘+Alt+2)',
    'Paragraph (Ctrl+Alt+0)': 'Paragraf (Ctrl+Alt+0)',
    'Paragraph (⌘+Alt+0)': 'Paragraf (⌘+Alt+0)',
    'Left Align (Ctrl+Shift+L)': 'Sola Hizala (Ctrl+ÜstKrktr+L)',
    'Left Align (⌘+Shift+L)': 'Sola Hizala (⌘+ÜstKrktr+L)',
    'Center Align (Ctrl+Shift+E)': 'Ortaya Hizala (Ctrl+ÜstKrktr+E)',
    'Center Align (⌘+Shift+E)': 'Ortaya Hizala (⌘+ÜstKrktr+E)',
    'Right Align (Ctrl+Shift+R)': 'Sağa Hizala (Ctrl+ÜstKrktr+R)',
    'Right Align (⌘+Shift+R)': 'Sağa Hizala (⌘+ÜstKrktr+R)',
    'Justify (Ctrl+Shift+J)': 'Yasla (Ctrl+ÜstKrktr+J)',
    'Justify (⌘+Shift+J)': 'Yasla (⌘+ÜstKrktr+J)',
    'Bullet List (Ctrl+Shift+8)': 'Madde İşareti Listesi (Ctrl+ÜstKrktr+8)',
    'Bullet List (⌘+Shift+8)': 'Madde İşareti Listesi (⌘+ÜstKrktr+8)',
    'Ordered List (Ctrl+Shift+7)': 'Sıralı Liste (Ctrl+ÜstKrktr+7)',
    'Ordered List (⌘+Shift+7)': 'Sıralı Liste (⌘+ÜstKrktr+7)',
    'Horizontal Rule': 'Yatay Cetvel',
    'Hard Break (Shift+Enter)': 'Sabit Satır Sonu (ÜstKrktr+Gir)',
    'Clear Format': 'Biçimlendirmeyi Temizle',
    'Undo (Ctrl+Z)': 'Geri Al (Ctrl+Z)',
    'Undo (⌘+Z)': 'Geri Al (⌘+Z)',
    'Redo (Ctrl+Y)': 'Yeniden Yap (Ctrl+Y)',
    'Redo (⌘+Y)': 'Yeniden Yap (⌘+Y)',
    // Tutorial steps:
    'tutorial-step-1-title': 'MyPatient 360°’ye hoş geldiniz! (1/12)',
    'tutorial-step-1': '<ul><li>MyPatient 360° deneyiminizi özelleştirmek için Profil sayfasını kullanın</li><li>Benzersiz bir kullanıcı adı oluşturun</li><li>Eğitimden erken çıkmak için ESC tuşuna basın</li></ul>',
    'tutorial-step-2-title': 'Ayarlar (2/12)',
    'tutorial-step-2': '<ul><li>Tercih ettiğiniz dili, açık temayı, koyu temayı ve saat dilimini ayarlayın</li><li>Mevcut temalardan bazılarını şimdi deneyin</li><li>Saat diliminizin doğru olduğunu doğrulayın</li></ul>',
    'tutorial-step-3-title': 'Tema Değiştirme (3/12)',
    'tutorial-step-3': '<ul><li>Tema değiştirme düğmesi her sayfanın başlığında mevcuttur.</li><li>Tercih ettiğiniz temalar arasında geçiş yapmak için tema geçişini kullanmayı deneyin</li></ul>',
    'tutorial-step-4-title': 'Gösterge Paneli (4/12)',
    'tutorial-step-4': '<ul><li>Gösterge Panosu, Oturumlarınızı yönetmek için merkezi bir konumdur. Burada şunları yapabilirsiniz:<br>&nbsp;&nbsp;&nbsp;yeni Oturumlar oluşturun<br>&nbsp;&nbsp;&nbsp;adlandırmak Oturumlar<br>&nbsp;&nbsp;&nbsp;hasta verilerinin anonimleştirilmesi<br>&nbsp;&nbsp;&nbsp;Oturumları açın ve URL’lerini kopyalayın<br>&nbsp;&nbsp;&nbsp;Oturumları paylaş<br>&nbsp;&nbsp;&nbsp;çoğaltmak Oturumlar<br>&nbsp;&nbsp;&nbsp;Oturumları sil</li><li>Bir Oturumu silmek kalıcıdır ve geri alınamaz, bu nedenle bu düğmeyi kullanırken dikkatli olun</li></ul>',
    'tutorial-step-5-title': 'Yeni Oturum Oluşturma (5/12)',
    'tutorial-step-5': '<ul><li>“Yeni oturum oluştur” iletişim kutusunu açın</li></ul>',
    'tutorial-step-6-title': 'Bir Başlangıç Şablonu Seçin (6/12)',
    'tutorial-step-6': '<ul><li>Oturum şablonları önceden tasarlanmış bir dizi araç içerir</li><li>Boş bir Oturum boş başlar</li><li>“Boş” seçeneğini seçin</li></ul>',
    'tutorial-step-7-title': 'Bu Yeni bir Oturum (7/12)',
    'tutorial-step-7': '<ul><li>Oturumlar zamanınızın çoğunu geçireceğiniz yerlerdir</li><li>“Araçlar Ekle” ye tıklayın</li></ul>',
    'tutorial-step-8-title': 'Araç Ekleme (8/12)',
    'tutorial-step-8': '<ul><li>Burada, bir Oturuma bir veya daha fazla yeni araç ekleyebilirsiniz</li><li>Klinik Zaman Çizelgesi ve Notlar araçlarını seçin, ardından “Araçlar ekle” ye tıklayın</li></ul>',
    'tutorial-step-9-title': 'Bir Hasta Seçin (9/12)',
    'tutorial-step-9': '<ul><li>Hemen kullanmaya başlayabileceğiniz bazı araçlar (Notes gibi)</li><li>Diğerleri (Klinik Zaman Çizelgesi gibi) hasta verilerine ihtiyaç duyar</li><li>Başlıktaki “Bir hasta bul” seçeneğine tıklayarak bir hasta seçin</li></ul>',
    'tutorial-step-10-title': 'Bir Hasta Seçin (10/12)',
    'tutorial-step-10': '<ul><li>Hastaları isme veya TKN’ye göre arama</li><li>Veritabanındaki her hastayı bulmak için “0” girin (bunu şimdi deneyin)</li><li>“Aramak” ya tıklayın veya klavyenizdeki “Enter” tuşuna basın</li></ul>',
    'tutorial-step-11-title': 'Bir Hasta Seçin (11/12)',
    'tutorial-step-11': '<ul><li>Belirli bir hastayı seçmek için herhangi bir hasta satırına tıklayın</li></ul>',
    'tutorial-step-12-title': 'Şimdi Keşfetmeyi Deneyin! (12/12)',
    'tutorial-step-12': '<ul><li>Seçilen hastanın verileri artık bu Oturumun araçlarında</li><li>Başlıktaki hasta bilgilerine tıklayarak istediğiniz zaman seçili hastayı değiştirin</li><li>Şimdi mevcut araçlara ve özelliklerine aşina olmanın keyfini çıkarın</li><li>Öğreticiyi sonlandırmak için “OK” a tıklayın</li></ul>',
    // ViewerTool
    'Download CSV': 'CSV İndir',
    'Download TSV': 'TSV İndir',
    'Download Markdown': 'Markdown İndir',
    'Download SVG': 'SVG İndir',
    'Download PDF': 'PDF İndir',
    'Download TXT': 'TXT İndir',
  },
  'zh_CN.UTF-8': {  // Chinese (Simplified)
    'dātma.360': 'dātma.360', // the product name shown as 'alt' on logo image
    // Tool titles:
    'Chat': '聊天',
    'Notes': '笔记',
    'Unknown Tool': '未知工具',
    'This error should not happen. Please contact dātma for assistance.': '这个错误不应该发生。请联系dātma寻求帮助。', // error message
    'No patient is currently selected.': '目前没有选择患者。',  // rendered in tool container in place of tool.
    // UI elements:
    'Add tools': '添加工具', // for add tools button
    'Authenticating…': '验证…', // on Login.vue page
    'Cancel': '取消', // to cancel modal dialogs
    'Change theme to:': '改主题为', // for theme button
    'Choose one or more tools to add:': '选择一个或多个工具来添加:', // for add tools modal
    'Close tool': '关闭工具', // for close button on each tool
    'Configure tool': '配置工具', // for configure tool button on each tool
    'Expand tool to full screen': '将工具扩展到全屏',  // for button on each tool
    'My Profile': '我的资料', // for button to switch to profile page
    'Open Control Dashboard': '打开控制仪表盘', // for dashboard button
    'Return tool to original size': '将工具恢复到原来的尺寸',  // for button on each tool
    'Start typing message…': '开始输入信息…', // for chat input placeholder
    'That session cannot be found.': '该通信期无法找到。',  // error message
    'Undo last action': '撤销上次行动', // for undo button
    'Update': '更新', // for add/remove collaborators modal
    'You do not have access to that session.': '你没有权限进入该通信期。',  // error message
    // Theme names:
    'dark': '暗', // generic name for kind of theme
    'light': '明', // generic name for kind of theme
    'dātma dark': 'dātma 暗',
    'dātma light': 'dātma 明',
    'Midnight': '子夜', // default theme name
    'MediumGray': '中灰色', // default theme name
    'Bright': '光明',
    'NightMarsh': '夜沼',
    'Creamy': '奶油色的',
    'Rusty': '生锈',
    'ODA 2022': 'ODA 2022',
    // Session page:
    'Patient ID': '患者ID',
    'Find a patient': '查找患者',  // used where patient info is displayed.
    'Deselect patient': 'TBD', // title for header deselect patient button
    'Search for a patient': '搜索患者', // search box placeholder text
    'Search': '检索', // search button
    'Name': '名字', // patient name
    'Gender': '性别', // patient gender
    'Birthdate': '出生日期', // patient birthdate
    'MRN': '病历号', // patient medical record number
    'No patients found.': '没有找到患者。', // empty search results message
    'timeout error': 'No response in allotted time. Please retry or contact dātma if this problem persists. TBD',
    'tool error': 'Tool returned error response. Please contact dātma for assistance. TBD',
    // Patient card:
    'Age': '年龄',
    'Primary Diagnosis': '主要诊断',
    'Staging': '癌症分期',
    // Profile page:
    'Your Displayed Name (as shown in URLs and in Chat sessions)': '您的显示名称(在URL和聊天会话中显示)',
    'Name cannot contain any of the following characters:': '名称不能包含以下任何字符:',  // error message
    'Your Displayed Name is not unique and will not be displayed in URLs.': '您的显示名称不是唯一的，不会在URL中显示。', // error message
    'Your Preferred Language': '您的偏好语言',
    'Chinese (Simplified)': '中文 (简体)',
    'Chinese (Traditional)': '中文 (繁体)',
    'English': '英语',
    'French': '法语',
    'Turkish': '土耳其语',
    'Your Preferred Light Theme': '您的偏好明主题',
    'Your Preferred Dark Theme': '您的偏好暗主题',
    'Time Zone': '时区',
    'Show Confirmation Before Deleting Session': '删除通信期前显示确认信息',
    'Sign Out': '登出', // sign out button text
    'Start Tutorial': '开始教程',
    'Return': '返回', // Meaning "Return to session" button text - keep it short.
    'Software Version:': '软件版本:',
    // Time Zones:
    'Niue (UTC−11)': '纽埃 (UTC−11)',
    'Hawaii–Aleutian Islands (UTC−10)': '夏威夷－阿留申群岛 (UTC−10)',
    'Alaska (UTC−9)': '阿拉斯加 (UTC−9)',
    'Pacific (UTC−8)': '太平洋 (UTC−8)',
    'Mountain (UTC−7)': '山 (UTC−7)',
    'Central (UTC−6)': '中部 (UTC−6)',
    'Eastern (UTC−5)': '东 (UTC−5)',
    'Atlantic (UTC−4)': '大西洋 (UTC−4)',
    'Brasília (UTC−3)': '巴西利亚 (UTC−3)',
    'South Georgia (UTC−2)': '南乔治亚 (UTC−2)',
    'Cape Verde (UTC−1)': '佛得角 (UTC−1)',
    'Western Europe (UTC)': '西欧 (UTC)',
    'Central Europe (UTC+1)': '中欧 (UTC+1)',
    'Eastern Europe (UTC+2)': '东欧 (UTC+2)',
    'Moscow (UTC+3)': '莫斯科 (UTC+3)',
    'Dubai (UTC+4)': '迪拜 (UTC+4)',
    'Maldives (UTC+5)': '马尔代夫 (UTC+5)',
    'India (UTC+5:30)': '印度 (UTC+5:30)',
    'Kyrgyzstan (UTC+6)': '吉尔吉斯斯坦 (UTC+6)',
    'Indochina (UTC+7)': '中印半岛 (UTC+7)',
    'China (UTC+8)': '北京 (UTC+8)',
    'Japan (UTC+9)': '日本 (UTC+9)',
    'Guam (UTC+10)': '关岛 (UTC+10)',
    'Vanuatu (UTC+11)': '瓦努阿图 (UTC+11)',
    'New Zealand (UTC+12)': '新西兰 (UTC+12)',
    // Dashboard page:
    'Control Dashboard': '控制仪表盘',
    'Create new session': '创建新的通信期',
    'Choose a starting template:': '选择一个起始模板:',
    'Sessions owned by me': '我的通信期',
    'Sessions shared with me': '与我分享的通信期',
    'Owner': '所有者',
    'Session ID': '通信期ID',
    'Session Name (optional)': '通信期名称 (可选)',
    'Your Session Name must be unique.': '您的通信期名称必须是唯一的。',  // error message
    'Session Name': '通信期名称',
    'Session Link': '通信期链接',
    'Number of Tools': '工具数量',
    'Locally Anonymized': '本地匿名化',
    'Copy Session Link': '复制通信期链接',
    'Sharing': '分享',
    'Collaborators': '合作者',
    'Viewers (read-only)': '观看者 (只读)',
    'Last accessed': '以前的访问',
    'Duplicate': '复制',
    'Sharing Status': 'TBD',
    'Delete': '删除',  // session
    'Go to session': '去往通信期', // button icon title
    'Copy session link for sharing': '复制通信期链接进行分享', // button icon title
    'Successfully copied!': '成功复制了!', // copy-to-clipboard success message
    'Add or remove collaborators': '添加或删除合作者', // button icon title
    'Add or remove viewers': '添加或删除观看者', // button icon title
    'All users with the link': '所有带有链接的用户', // collaborators modal dialog
    'Make a copy of this session': '复制这个通信期', // button icon title
    'Collaborator': 'TBD', // non-button icon title, sharing status
    'Viewer': 'TBD', // non-button icon title, sharing status
    'Delete this session': '删除这个通信期', // button icon title
    'never': '从不',  // displayed in 'Last accessed' column if never displayed
    'Delete Session': '删除通信期', // Title of session deletion confirmation modal
    'Deleting this session is permanent and cannot be undone.': '删除这个通信期是永久性的，不能撤销。', // Text of session deletion confirmation modal
    // Dashboard tips
    'dashboard-tip-session-name': 'TBD',
    'dashboard-tip-session-link': 'TBD',
    'dashboard-tip-locally-anonymized': 'TBD',
    'dashboard-copy-tip-session-link': 'TBD',
    'dashboard-tip-collaborators': 'TBD',
    'dashboard-tip-viewers': 'TBD',
    'dashboard-tip-duplicate-session': 'TBD',
    'dashboard-tip-delete-session': 'TBD',
    'shared-dashboard-tip-session-name': 'TBD',
    'shared-dashboard-tip-locally-anonymized': 'TBD',
    'shared-dashboard-tip-session-link': 'TBD',
    'shared-dashboard-tip-duplicate-session': 'TBD',
    'shared-dashboard-tip-sharing-status': 'TBD',
    // Notes Tool Menu Bar Item Titles
    'Bold (Ctrl+B)': '粗体 (Ctrl+B)',
    'Bold (⌘+B)': '粗体 (⌘+B)',
    'Italic (Ctrl+I)': '斜体 (Ctrl+I)',
    'Italic (⌘+I)': '斜体 (⌘+I)',
    'Strike (Ctrl+Shift+X)': '删除线 (Ctrl+Shift+X)',
    'Strike (⌘+Shift+X)': '删除线 (⌘+Shift+X)',
    'Heading 1 (Ctrl+Alt+1)': '标题1 (Ctrl+Alt+1)',
    'Heading 1 (⌘+Alt+1)': '标题1 (⌘+Alt+1)',
    'Heading 2 (Ctrl+Alt+2)': '标题2 (Ctrl+Alt+2)',
    'Heading 2 (⌘+Alt+2)': '标题2 (⌘+Alt+2)',
    'Paragraph (Ctrl+Alt+0)': '段落 (Ctrl+Alt+0)',
    'Paragraph (⌘+Alt+0)': '段落 (⌘+Alt+0)',
    'Left Align (Ctrl+Shift+L)': '左对齐 (Ctrl+Shift+L)',
    'Left Align (⌘+Shift+L)': '左对齐 (⌘+Shift+L)',
    'Center Align (Ctrl+Shift+E)': '居中对齐 (Ctrl+Shift+E)',
    'Center Align (⌘+Shift+E)': '居中对齐 (⌘+Shift+E)',
    'Right Align (Ctrl+Shift+R)': '右对齐 (Ctrl+Shift+R)',
    'Right Align (⌘+Shift+R)': '右对齐 (⌘+Shift+R)',
    'Justify (Ctrl+Shift+J)': '两端对齐 (Ctrl+Shift+J)',
    'Justify (⌘+Shift+J)': '两端对齐 (⌘+Shift+J)',
    'Bullet List (Ctrl+Shift+8)': '项目符号列表 (Ctrl+Shift+8)',
    'Bullet List (⌘+Shift+8)': '项目符号列表 (⌘+Shift+8)',
    'Ordered List (Ctrl+Shift+7)': '有序列表 (Ctrl+Shift+7)',
    'Ordered List (⌘+Shift+7)': '有序列表 (⌘+Shift+7)',
    'Horizontal Rule': '水平线',
    'Hard Break (Shift+Enter)': '固定换行 (Shift+Enter)',
    'Clear Format': '清除格式',
    'Undo (Ctrl+Z)': '撤销 (Ctrl+Z)',
    'Undo (⌘+Z)': '撤销 (⌘+Z)',
    'Redo (Ctrl+Y)': '重做 (Ctrl+Y)',
    'Redo (⌘+Y)': '重做 (⌘+Y)',
    // Tutorial steps:
    'tutorial-step-1-title': '欢迎到 MyPatient 360°! (1/12)',
    'tutorial-step-1': '<ul><li>使用个人资料页来定制您的MyPatient 360°体验</li><li>制作一个独特的用户名</li><li>按ESC键，提前退出教程</li></ul>',
    'tutorial-step-2-title': '喜好设定 (2/12)',
    'tutorial-step-2': '<ul><li>设置你喜欢的语言、浅色主题、深色主题和时区</li><li>现在试试一些可用的主题</li><li>验证你的时区是否正确</li></ul>',
    'tutorial-step-3-title': '主题切换 (3/12)',
    'tutorial-step-3': '<ul><li>主题切换按钮在每个页面的页眉处都有。</li><li>尝试使用主题切换器在你喜欢的主题之间切换</li></ul>',
    'tutorial-step-4-title': '仪表盘 (4/12)',
    'tutorial-step-4': '<ul><li>仪表板是管理你的通信期的中心位置。在这里，你可以:<br>&nbsp;&nbsp;&nbsp;创建新的通信期<br>&nbsp;&nbsp;&nbsp;命名通信期<br>&nbsp;&nbsp;&nbsp;对患者数据进行匿名化处理<br>&nbsp;&nbsp;&nbsp;打开通信期并复制其URL<br>&nbsp;&nbsp;&nbsp;分享通信期<br>&nbsp;&nbsp;&nbsp;复制通信期<br>&nbsp;&nbsp;&nbsp;删除通信期</li><li>删除通信期是永久性的，不能被撤销，所以当你使用该按钮时要小心。</li></ul>',
    'tutorial-step-5-title': '创建一个新的通信期 (5/12)',
    'tutorial-step-5': '<ul><li>打开 “创建新的通信期” 对话框</li></ul>',
    'tutorial-step-6-title': '选择一个起始模板 (6/12)',
    'tutorial-step-6': '<ul><li>通信期模板包含一套预先设计好的工具</li><li>空白通信期开始时是空的</li><li>选择 “空白” 选项</li></ul>',
    'tutorial-step-7-title': '这是一个新的通信期 (7/12)',
    'tutorial-step-7': '<ul><li>通信期是你将花费大部分时间的地方</li><li>点击 “添加工具”</li></ul>',
    'tutorial-step-8-title': '添加一个工具 (8/12)',
    'tutorial-step-8': '<ul><li>在这里，你可以向一个通信期添加一个或多个新工具</li><li>选择临床年表和笔记工具，然后点击 “添加工具”</li></ul>',
    'tutorial-step-9-title': '选择一个患者 (9/12)',
    'tutorial-step-9': '<ul><li>一些工具（如笔记）你可以立即开始使用</li><li>其他（如临床年表）需要患者数据</li><li>通过点击标题中的 “查找患者” 选择一个患者</li></ul>',
    'tutorial-step-10-title': '选择一个患者 (10/12)',
    'tutorial-step-10': '<ul><li>按姓名或病历号搜索患者</li><li>输入 “0” 来查找数据库中的每一个患者（现在就试试这个）</li><li>点击 “检索” 或按键盘上的回车键</li></ul>',
    'tutorial-step-11-title': '选择一个患者 (11/12)',
    'tutorial-step-11': '<ul><li>点击任何患者行，选择一个特定的患者</li></ul>',
    'tutorial-step-12-title': '现在尝试探索! (12/12)',
    'tutorial-step-12': '<ul><li>所选患者的数据现在在本通信期的工具中</li><li>通过点击标题中的患者信息，随时改变选定的患者</li><li>现在，请尽情地熟悉可用的工具和它们的功能吧</li><li>点击 “OK”，结束教程</li></ul>',
    // ViewerTool
    'Download CSV': '下载CSV',
    'Download TSV': '下载TSV',
    'Download Markdown': '下载Markdown',
    'Download SVG': '下载SVG',
    'Download PDF': '下载PDF',
    'Download TXT': '下载TXT',
  },
  'zh_TW.UTF-8': { // Chinese (Traditional)
    'dātma.360': 'dātma.360', // the product name shown as 'alt' on logo image
    // Tool titles:
    'Chat': '聊天',
    'Notes': '筆記',
    'Unknown Tool': '未知工具',
    'This error should not happen. Please contact dātma for assistance.': '這個錯誤不應該發生。請聯系dātma尋求幫助。', // error message
    'No patient is currently selected.': '目前沒有選擇患者。',  // rendered in tool container in place of tool.
    // UI elements:
    'Add tools': '添加工具', // for add tools button
    'Authenticating…': '驗證…', // on Login.vue page
    'Cancel': '取消', // to cancel modal dialogs
    'Change theme to:': '改主題為', // for theme button
    'Choose one or more tools to add:': '選擇一個或多個工具來添加:', // for add tools modal
    'Close tool': '關閉工具', // for close button on each tool
    'Configure tool': '配置工具', // for configure tool button on each tool
    'Expand tool to full screen': '將工具擴展到全屏',  // for button on each tool
    'My Profile': '我的資料', // for button to switch to profile page
    'Open Control Dashboard': '打開控制儀表盤', // for dashboard button
    'Return tool to original size': '將工具恢複到原來的尺寸',  // for button on each tool
    'Start typing message…': '開始輸入信息…', // for chat input placeholder
    'That session cannot be found.': '該通信期無法找到。',  // error message
    'Undo last action': '撤銷上次行動', // for undo button
    'Update': '更新', // for add/remove collaborators modal
    'You do not have access to that session.': '你沒有權限進入該通信期。',  // error message
    // Theme names:
    'dark': '暗', // generic name for kind of theme
    'light': '明', // generic name for kind of theme
    'dātma dark': 'dātma 暗',
    'dātma light': 'dātma 明',
    'Midnight': '子夜',
    'MediumGray': '中灰色',
    'Bright': '光明',
    'NightMarsh': '夜沼',
    'Creamy': '奶油色的',
    'Rusty': '生鏽',
    'ODA 2022': 'ODA 2022',
    // Session page:
    'Patient ID': '患者ID',
    'Find a patient': '查找患者',  // used where patient info is displayed.
    'Deselect patient': 'TBD', // title for header deselect patient button
    'Search for a patient': '搜索患者', // search box placeholder text
    'Search': '檢索', // search button
    'Name': '名字', // patient name
    'Gender': '性別', // patient gender
    'Birthdate': '出生日期', // patient birthdate
    'MRN': '病曆號', // patient medical record number
    'No patients found.': '沒有找到患者。', // empty search results message
    'timeout error': 'No response in allotted time. Please retry or contact dātma if this problem persists. TBD',
    'tool error': 'Tool returned error response. Please contact dātma for assistance. TBD',
    // Patient card:
    'Age': '年齡',
    'Primary Diagnosis': '主要診斷',
    'Staging': '癌症分期',
    // Profile page:
    'Your Displayed Name (as shown in URLs and in Chat sessions)': '您的顯示名稱(在URL和聊天會話中顯示)',
    'Name cannot contain any of the following characters:': '名稱不能包含以下任何字符:',  // error message
    'Your Displayed Name is not unique and will not be displayed in URLs.': '您的顯示名稱不是唯一的，不會在URL中顯示。', // error message
    'Your Preferred Language': '您的偏好語言',
    'Chinese (Simplified)': '中文 (簡體)',
    'Chinese (Traditional)': '中文 (繁體)',
    'English': '英語',
    'French': '法語',
    'Turkish': '土耳其語',
    'Your Preferred Light Theme': '您的偏好明主題',
    'Your Preferred Dark Theme': '您的偏好暗主題',
    'Time Zone': '時區',
    'Show Confirmation Before Deleting Session': '刪除通信期前顯示確認信息',
    'Sign Out': '登出', // sign out button text
    'Start Tutorial': '開始教程',
    'Return': '返回', // Meaning "Return to session" button text - keep it short.
    'Software Version:': '軟件版本:',
    // Time Zones:
    'Niue (UTC−11)': '紐埃 (UTC−11)',
    'Hawaii–Aleutian Islands (UTC−10)': '夏威夷－阿留申群島 (UTC−10)',
    'Alaska (UTC−9)': '阿拉斯加 (UTC−9)',
    'Pacific (UTC−8)': '太平洋 (UTC−8)',
    'Mountain (UTC−7)': '山 (UTC−7)',
    'Central (UTC−6)': '中部 (UTC−6)',
    'Eastern (UTC−5)': '東 (UTC−5)',
    'Atlantic (UTC−4)': '大西洋 (UTC−4)',
    'Brasília (UTC−3)': '巴西利亞 (UTC−3)',
    'South Georgia (UTC−2)': '南喬治亞 (UTC−2)',
    'Cape Verde (UTC−1)': '維德角 (UTC−1)',
    'Western Europe (UTC)': '西歐 (UTC)',
    'Central Europe (UTC+1)': '中歐 (UTC+1)',
    'Eastern Europe (UTC+2)': '東歐 (UTC+2)',
    'Moscow (UTC+3)': '莫斯科 (UTC+3)',
    'Dubai (UTC+4)': '杜拜 (UTC+4)',
    'Maldives (UTC+5)': '馬爾地夫 (UTC+5)',
    'India (UTC+5:30)': '印度 (UTC+5:30)',
    'Kyrgyzstan (UTC+6)': '吉爾吉斯 (UTC+6)',
    'Indochina (UTC+7)': '中印半島 (UTC+7)',
    'China (UTC+8)': '中國 (UTC+8)',
    'Japan (UTC+9)': '日本 (UTC+9)',
    'Guam (UTC+10)': '關島 (UTC+10)',
    'Vanuatu (UTC+11)': '萬那杜 (UTC+11)',
    'New Zealand (UTC+12)': '紐西蘭 (UTC+12)',
    // Dashboard page:
    'Control Dashboard': '控制儀表盤',
    'Create new session': '創建新的通信期',
    'Choose a starting template:': '選擇一個起始模板:',
    'Sessions owned by me': '我的通信期',
    'Sessions shared with me': '與我分享的通信期',
    'Owner': '所有者',
    'Session ID': '通信期ID',
    'Session Name (optional)': '通信期名稱 (可選)',
    'Your Session Name must be unique.': '您的通信期名稱必須是唯一的。',  // error message
    'Session Name': '通信期名稱',
    'Session Link': '通信期鏈接',
    'Number of Tools': '工具數量',
    'Locally Anonymized': '本地匿名化',
    'Copy Session Link': '複制通信期鏈接',
    'Sharing': '分享',
    'Collaborators': '合作者',
    'Viewers (read-only)': '觀看者 (只讀)',
    'Last accessed': '以前的訪問',
    'Duplicate': '複製',
    'Sharing Status': 'TBD',
    'Delete': '刪除',  // session
    'Go to session': '去往通信期', // button icon title
    'Copy session link for sharing': '複製通信期鏈接進行分享', // button icon title
    'Successfully copied!': '成功複製了!', // copy-to-clipboard success message
    'Add or remove collaborators': '添加或刪除合作者', // button icon title
    'Add or remove viewers': '添加或刪除觀看者', // button icon title
    'All users with the link': '所有帶有鏈接的用戶', // collaborators modal dialog
    'Make a copy of this session': '複製這個通信期', // button icon title
    'Collaborator': 'TBD', // non-button icon title, sharing status
    'Viewer': 'TBD', // non-button icon title, sharing status
    'Delete this session': '刪除這個通信期', // button icon title
    'never': '從不',  // displayed in 'Last accessed' column if never displayed
    'Delete Session': '刪除通信期', // Title of session deletion confirmation modal
    'Deleting this session is permanent and cannot be undone.': '刪除這個通信期是永久性的，不能撤銷。', // Text of session deletion confirmation modal
    // Dashboard tips
    'dashboard-tip-session-name': 'TBD',
    'dashboard-tip-session-link': 'TBD',
    'dashboard-tip-locally-anonymized': 'TBD',
    'dashboard-copy-tip-session-link': 'TBD',
    'dashboard-tip-collaborators': 'TBD',
    'dashboard-tip-viewers': 'TBD',
    'dashboard-tip-duplicate-session': 'TBD',
    'dashboard-tip-delete-session': 'TBD',
    'shared-dashboard-tip-session-name': 'TBD',
    'shared-dashboard-tip-locally-anonymized': 'TBD',
    'shared-dashboard-tip-session-link': 'TBD',
    'shared-dashboard-tip-duplicate-session': 'TBD',
    'shared-dashboard-tip-sharing-status': 'TBD',
    // Notes Tool Menu Bar Item Titles
    'Bold (Ctrl+B)': '粗體 (Ctrl+B)',
    'Bold (⌘+B)': '粗體 (⌘+B)',
    'Italic (Ctrl+I)': '斜體 (Ctrl+I)',
    'Italic (⌘+I)': '斜體 (⌘+I)',
    'Strike (Ctrl+Shift+X)': '刪除線 (Ctrl+Shift+X)',
    'Strike (⌘+Shift+X)': '刪除線 (⌘+Shift+X)',
    'Heading 1 (Ctrl+Alt+1)': '標題1 (Ctrl+Alt+1)',
    'Heading 1 (⌘+Alt+1)': '標題1 (⌘+Alt+1)',
    'Heading 2 (Ctrl+Alt+2)': '標題2 (Ctrl+Alt+2)',
    'Heading 2 (⌘+Alt+2)': '標題2 (⌘+Alt+2)',
    'Paragraph (Ctrl+Alt+0)': '段落 (Ctrl+Alt+0)',
    'Paragraph (⌘+Alt+0)': '段落 (⌘+Alt+0)',
    'Left Align (Ctrl+Shift+L)': '左對齊 (Ctrl+Shift+L)',
    'Left Align (⌘+Shift+L)': '左對齊 (⌘+Shift+L)',
    'Center Align (Ctrl+Shift+E)': '居中對齊 (Ctrl+Shift+E)',
    'Center Align (⌘+Shift+E)': '居中對齊 (⌘+Shift+E)',
    'Right Align (Ctrl+Shift+R)': '右對齊 (Ctrl+Shift+R)',
    'Right Align (⌘+Shift+R)': '右對齊 (⌘+Shift+R)',
    'Justify (Ctrl+Shift+J)': '兩端對齊 (Ctrl+Shift+J)',
    'Justify (⌘+Shift+J)': '兩端對齊 (⌘+Shift+J)',
    'Bullet List (Ctrl+Shift+8)': '項目符號列表 (Ctrl+Shift+8)',
    'Bullet List (⌘+Shift+8)': '項目符號列表 (⌘+Shift+8)',
    'Ordered List (Ctrl+Shift+7)': '有序列表 (Ctrl+Shift+7)',
    'Ordered List (⌘+Shift+7)': '有序列表 (⌘+Shift+7)',
    'Horizontal Rule': '水平線',
    'Hard Break (Shift+Enter)': '固定換行 (Shift+Enter)',
    'Clear Format': '清除格式',
    'Undo (Ctrl+Z)': '撤銷 (Ctrl+Z)',
    'Undo (⌘+Z)': '撤銷 (⌘+Z)',
    'Redo (Ctrl+Y)': '重做 (Ctrl+Y)',
    'Redo (⌘+Y)': '重做 (⌘+Y)',
    // Tutorial steps:
    'tutorial-step-1-title': '歡迎到 MyPatient 360°! (1/12)',
    'tutorial-step-1': '<ul><li>使用個人資料頁來定制您的MyPatient 360°體驗</li><li>制作一個獨特的用戶名</li><li>按ESC鍵，提前退出教程</li></ul>',
    'tutorial-step-2-title': '喜好設定 (2/12)',
    'tutorial-step-2': '<ul><li>設置你喜歡的語言、淺色主題、深色主題和時區</li><li>現在試試一些可用的主題</li><li>驗證你的時區是否正確</li></ul>',
    'tutorial-step-3-title': '主題切換 (3/12)',
    'tutorial-step-3': '<ul><li>主題切換按鈕在每個頁面的頁眉處都有。</li><li>嘗試使用主題切換器在你喜歡的主題之間切換</li></ul>',
    'tutorial-step-4-title': '儀表盤 (4/12)',
    'tutorial-step-4': '<ul><li>儀表板是管理你的通信期的中心位置。在這裏，你可以:<br>&nbsp;&nbsp;&nbsp;創建新的通信期<br>&nbsp;&nbsp;&nbsp;命名通信期<br>&nbsp;&nbsp;&nbsp;對患者數據進行匿名化處理<br>&nbsp;&nbsp;&nbsp;打開通信期並複制其URL<br>&nbsp;&nbsp;&nbsp;分享通信期<br>&nbsp;&nbsp;&nbsp;複制通信期<br>&nbsp;&nbsp;&nbsp;刪除通信期</li><li>刪除通信期是永久性的，不能被撤銷，所以當你使用該按鈕時要小心。</li></ul>',
    'tutorial-step-5-title': '創建一個新的通信期 (5/12)',
    'tutorial-step-5': '<ul><li>打開「創建新的通信期」對話框</li></ul>',
    'tutorial-step-6-title': '選擇一個起始模板 (6/12)',
    'tutorial-step-6': '<ul><li>通信期模板包含一套預先設計好的工具</li><li>空白通信期開始時是空的</li><li>選擇「空白」選項</li></ul>',
    'tutorial-step-7-title': '這是一個新的通信期 (7/12)',
    'tutorial-step-7': '<ul><li>通信期是你將花費大部分時間的地方</li><li>點擊「添加工具」</li></ul>',
    'tutorial-step-8-title': '添加一個工具 (8/12)',
    'tutorial-step-8': '<ul><li>在這裏，你可以向一個通信期添加一個或多個新工具</li><li>選擇臨床年表和筆記工具，然後點擊「添加工具」</li></ul>',
    'tutorial-step-9-title': '選擇一個患者 (9/12)',
    'tutorial-step-9': '<ul><li>一些工具（如筆記）你可以立即開始使用</li><li>其他（如臨床年表）需要患者數據</li><li>通過點擊標題中的「查找患者」選擇一個患者</li></ul>',
    'tutorial-step-10-title': '選擇一個患者 (10/12)',
    'tutorial-step-10': '<ul><li>按姓名或病曆號搜索患者</li><li>輸入「0」來查找數據庫中的每一個患者（現在就試試這個）</li><li>點擊「檢索」或按鍵盤上的回車鍵</li></ul>',
    'tutorial-step-11-title': '選擇一個患者 (11/12)',
    'tutorial-step-11': '<ul><li>點擊任何患者行，選擇一個特定的患者</li></ul>',
    'tutorial-step-12-title': '現在嘗試探索! (12/12)',
    'tutorial-step-12': '<ul><li>所選患者的數據現在在本通信期的工具中</li><li>通過點擊標題中的患者信息，隨時改變選定的患者</li><li>現在，請盡情地熟悉可用的工具和它們的功能吧</li><li>點擊「OK」，結束教程</li></ul>',
    // ViewerTool
    'Download CSV': '下載CSV',
    'Download TSV': '下載TSV',
    'Download Markdown': '下載Markdown',
    'Download SVG': '下載SVG',
    'Download PDF': '下載PDF',
    'Download TXT': '下載TXT',
  },
}

export const translateFunc = (language, key) => {
  const lookup = translations[language]
  if (!(key in lookup) || (language !== 'en_US.UTF-8' && !lookup[key])) {
    alert(`Missing translation for key ${key} in language ${language}`)
    return 'MISSING TRANSLATION'
  }
  if (language === 'en_US.UTF-8' && !lookup[key]) { return key }
  return lookup[key]
}

export const languages = {
  'Chinese (Simplified)': 'zh_CN.UTF-8',
  'Chinese (Traditional)': 'zh_TW.UTF-8',
  'English': 'en_US.UTF-8',
  'French': 'fr_CA.UTF-8',
  'Turkish': 'tr_TR.UTF-8',
}

export default {
  install: (app) => {
    app.provide('languages', languages)

    const translationLanguage = ref('en_US.UTF-8')

    const setTranslationLanguage = (lang) => {
      translationLanguage.value = lang
      if (!translations[lang]) {
        alert(`Language ${lang} not supported!`)
      }
    }
    app.provide('setTranslationLanguage', setTranslationLanguage)
    app.config.globalProperties.$setTranslationLanguage = setTranslationLanguage

    const tr = computed(() => (key) => translateFunc(translationLanguage.value, key))
    app.provide('tr', tr)
  }
}
