<template>
  <div class="system-toast" v-if="showSystemToast" :style="systemToastStyle">
    {{ showSystemToast }}
  </div>
  <router-view :key="$route.fullPath" />
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const setBodyBackgroundColor = (name) => {
      const body = document.querySelector('body')
      body.style.backgroundColor = themeColors[name].baseBackgroundColor
      body.style.setProperty('--selectionBackground', themeColors[store.getters.currentThemeName].buttonBackgroundColor)
      body.style.setProperty('--selectionText', themeColors[store.getters.currentThemeName].buttonTextColor)
      body.style.setProperty('--scrollbarBG', themeColors[store.getters.currentThemeName].appScrollBarBackgroundColor)
      body.style.setProperty('--thumbBG', themeColors[store.getters.currentThemeName].appScrollBarColor)
      body.style.setProperty('--thumbHoverBG', themeColors[store.getters.currentThemeName].accentColor)
    }

    watchEffect(() => setBodyBackgroundColor(store.getters.currentThemeName))

    const language = computed(() => store.getters.profile.language)
    watch(language, () => {
      const bodyStyle = document.body.style
      if (language.value === 'zh_CN.UTF-8') {
        bodyStyle.fontFamily = '"Noto Sans SC", sans-serif'
      }
      else if (language.value === 'zh_TW.UTF-8') {
        bodyStyle.fontFamily = '"Noto Sans TC", sans-serif'
      }
      else {
        bodyStyle.fontFamily = '"Roboto", sans-serif'
      }
    })

    const systemToastStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].errorBackgroundColor,
        color: themeColors[store.getters.currentThemeName].errorTextColor,
      }
    })

    const showSystemToast = computed(() => store.getters.showSystemToast)

    return {
      showSystemToast,
      systemToastStyle,
    }
  }
}
</script>

<style lang="scss">
@import '@/common/tutorial.scss';
@import '@/common/scrollbar.scss';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans SC';
  src: local('Noto Sans SC'),
    url('./fonts/NotoSansSC-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans TC';
  src: local('Noto Sans TC'),
    url('./fonts/NotoSansTC-Regular.otf') format('opentype');
}

* {
  box-sizing: border-box;
  transition: transform 0.15s;
}

body {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  background-color: #ddd;
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh; // Required to get tutorial to work!
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

.system-toast {
  z-index: 100;
  position: fixed;
  top: 3rem;
  left: 50%;
  margin-left: -12.5rem;
  height: 4rem;
  line-height: 4rem;
  width: 25rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>