// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { v4 as uuidv4 } from 'uuid'

import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'
import { maxToolRows, minToolColumns, minToolRows, numColumns, sanitizeUrlSegment } from '@/common/shared.js'

import { store } from './index.js'

export const addOrUpdateTool = (context, { toolName, toolTitle, payload, toolId, width, height, row, col }) => {
  return new Promise((resolve, reject) => {
    const sessionId = context.rootGetters.sessionId
    if (!sessionId) { reject('not in a session'); return }
    const toolIndex = context.rootGetters.toolsMapIndex[toolId]
    if (!toolId || toolIndex === undefined) {
      const id = toolId || uuidv4()
      const closeOnPatientChange = (payload && 'closeOnPatientChange' in payload) ? payload.closeOnPatientChange : true
      const tool = {
        id,
        col: col || 0,
        row: row || 0,
        width: width || numColumns,
        height: height || 5,
        componentName: toolName,
        closeOnPatientChange,
        name: {
          'en_US.UTF-8': toolTitle,
          'fr_CA.UTF-8': toolTitle,
          'tr_TR.UTF-8': toolTitle,
          'zh_CN.UTF-8': toolTitle,
          'zh_TW.UTF-8': toolTitle,
        },
        payload,
      }
      if (tool.col < 0) { tool.col = 0 }
      if (tool.col > numColumns - minToolColumns) { tool.col = numColumns - minToolColumns }
      if (tool.row < 0) { tool.row = 0 }
      if (tool.width < minToolColumns) { tool.width = minToolColumns }
      if (tool.width > numColumns - tool.col) { tool.width = numColumns - tool.col }
      if (tool.height < minToolRows) { tool.height = minToolRows }
      if (tool.height > maxToolRows) { tool.rows = maxToolRows }
      let toolIndex = 0
      context.rootGetters.tools.forEach((t, index) => {
        if (t.row <= tool.row) { toolIndex = index }
      })
      store.dispatch('addToolAtIndex', { tool, toolIndex })
      refreshToolPositions()
      resolve(id)
      return
    }

    const tool = context.rootGetters.tools[toolIndex]
    if (!tool) { reject('no such tool'); return }
    tool.name = {
      'en_US.UTF-8': toolTitle,
      'fr_CA.UTF-8': toolTitle,
      'tr_TR.UTF-8': toolTitle,
      'zh_CN.UTF-8': toolTitle,
      'zh_TW.UTF-8': toolTitle,
    }
    const sessionOwnerId = context.rootGetters.sessionOwnerId
    store.dispatch('updateToolPayload', { payload, sessionOwnerId, sessionId, toolId, publish: true })
    resolve(toolId)
  })
}

export const duplicateSessionTemplate = (context, oldSession) => {
  const tools = JSON.parse(JSON.stringify(oldSession.tools || []))
  tools.forEach((t) => { t.id = uuidv4() })
  const id = uuidv4()
  const session = {
    id,
    name: `${oldSession.name}-${id.slice(0, 4)}`,
    ownerId: context.rootGetters.userId,
    ownerName: context.rootGetters.profile.displayName,
    locallyAnonymized: !!oldSession.locallyAnonymized,
    patient: oldSession.patient || {}, // used in clinicianView only
    lastAccessed: new Date().getTime(),
    collaborators: [],
    collaborateWithAnyoneLink: !!oldSession.collaborateWithAnyoneLink,
    viewers: [],
    viewerWithAnyoneLink: !!oldSession.viewerWithAnyoneLink,
    toolCount: tools.length,
  }
  store.dispatch('createNewSession', { session, publish: true })
  store.dispatch('createNewSessionTools', { session, tools })
  // Redirect to the new session.
  const url = `/user/${sanitizeUrlSegment(session.ownerName)}/session/${session.id}`
  store.dispatch('routeToUrl', url)
}

export const getSavedSession = (context, { sessionOwnerId, sessionId }) => {
  // Security: Only perform the commit if this user is allowed to access
  // the requested session!
  const hasAccess = context.rootGetters.allAccessibleSessions.some(
    (s) => s.ownerId === sessionOwnerId && s.id === sessionId)
  if (hasAccess) {
    // These methods allow the tool to get information about itself.
    const getTool = (toolId) => {
      if (!toolId) { return undefined }
      const toolIndex = context.rootGetters.toolsMapIndex[toolId]
      if (toolIndex < 0) { return undefined }
      return context.rootGetters.tools[toolIndex]
    }
    window.ux2.toolCol = (toolId) => {
      const tool = getTool(toolId)
      return tool ? tool.col : 0
    }
    window.ux2.toolRow = (toolId) => {
      const tool = getTool(toolId)
      return tool ? tool.row : 0
    }
    window.ux2.toolWidth = (toolId) => {
      const tool = getTool(toolId)
      return tool ? tool.width : numColumns
    }
    window.ux2.toolHeight = (toolId) => {
      const tool = getTool(toolId)
      return tool ? tool.height : 5
    }
    context.commit('getSavedSession', { sessionOwnerId, sessionId })
  }
}